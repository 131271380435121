import permissions from "./permissions";
import BlockIcon from "@mui/icons-material/Block";
import HistoryIcon from "@mui/icons-material/History";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HomeOutlinedIcon from "@mui/icons-material/Home";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import PollOutlinedIcon from "@mui/icons-material/Poll";
import PsychologyOutlinedIcon from "@mui/icons-material/Functions";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import PeopleOutlinedIcon from "@mui/icons-material/People";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import HandshakeOutlinedIcon from "@mui/icons-material/Handshake";
import AssessmentOutlinedIcon from "@mui/icons-material/Assessment";
import MedicationOutlinedIcon from "@mui/icons-material/Medication";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2";
import DescriptionOutlinedIcon from "@mui/icons-material/Description";
import DataSaverOnOutlinedIcon from "@mui/icons-material/DataSaverOn";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccounts";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaic";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedIn";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumber";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturing";

import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTree";
import ArticleIcon from '@mui/icons-material/Article';

export const MenuItems = [
    {
        id: "home",
        title: "Home",
        type: "item",
        icon: <HomeOutlinedIcon/>,
        url: "/home",
        permissions: [
            permissions.ViewDashboardStatistics,
            permissions.ViewProductChartsReport,
        ],
    },
    {
        id: "verify-status",
        title: "Verify Status",
        type: "item",
        icon: process.env.PUBLIC_URL + "/assets/svgs/scanner.svg",
        url: "/verify-status",
        permissions: [permissions.Verify_Status],
    },
    {
        id: "verification",
        title: "Verification",
        type: "item",
        icon: process.env.PUBLIC_URL + "/assets/svgs/verification.svg",
        url: "/verification",
        permissions: [permissions.ItemVerification],
    },
    {
        id: "point-of-sale",
        title: "Point of Sale",
        type: "item",
        icon: <CreditCardIcon/>,
        url: "/point-of-sale",
        permissions: [permissions.POS_Sell],
    },
    // {
    //     id: "serialization",
    //     title: "Serialization",
    //     type: "item",
    //     icon: <QrCodeRoundedIcon/>,
    //     url: "/serialization",
    //     permissions: [permissions.SO_View],
    // },
    // {
    //     id: "machines",
    //     title: "Machines",
    //     type: "item",
    //     icon: <PrecisionManufacturingOutlinedIcon/>,
    //     url: "/machines",
    //     permissions: [permissions.Machine_View],
    // },
    {
        id: "logistic-operations",
        title: "Logistic Operations",
        type: "collapse",
        icon: <ArticleIcon/>,

        permissions: [],
        children: [
            {
                id: "files",
                title: "Shipment Files",
                type: "item",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/shipment.svg",
                url: "/files",
                permissions: [permissions.InboundFileView],
            },
            {
                type: "item",
                title: "Inbound",
                id: "inbound-orders",
                url: "/inbound-orders/receive-by-order",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/warehouse.svg",
                permissions: [permissions.InboundView],
            },
            {
                id: "outbound-orders",
                title: "Outbound",
                type: "item",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/truck.svg",
                url: "/outbound-orders/shipout-by-order",
                permissions: [permissions.OutboundView],
            },
            {
                id: "pack",
                title: "Pack",
                type: "item",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/pack.svg",
                url: "/pack/aggregation",
                permissions: [permissions.PackView],
            },
            {
                id: "unpack",
                title: "Unpack",
                type: "item",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/unpack.svg",
                url: "/unpack/parent",
                permissions: [permissions.Disaggregation],
            },
            {
                id: "activate",
                title: "Activate",
                type: "item",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/activate.svg",
                url: "/activate",
                permissions: [permissions.Activate],
            },
            {
                id: "decommision",
                title: "Decommission",
                type: "item",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/deactivate.svg",
                url: "/decommission",
                permissions: [permissions.DecomissionView],
            },
            {
                type: "item",
                title: "Move Orders",
                id: "move-orders",
                url: "/move-orders",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/move-order.svg",
                permissions: [permissions.MO_View],
            },
            {
                id: "cycle-count",
                title: "Cycle Count",
                type: "item",
                // icon: <PollOutlinedIcon/>,
                url: "/cycle-count",
                permissions: [permissions.CycleCount_View],
            },
        ]
    },
    {
        id: "products",
        title: "Master Data",
        type: "collapse",
        icon: <MedicationOutlinedIcon/>,
        children: [
            {
                id: "partners",
                title: "Partners",
                type: "item",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/handshake.svg",
                url: "/partners",
                permissions: [permissions.PartnerView],
            },
            {
                id: "products",
                title: "Products",
                type: "item",
                // icon: process.env.PUBLIC_URL + "/assets/svgs/products.svg",
                url: "/products",
                permissions: [permissions.ProductView],
            },
            {
                id: "skus",
                title: "SKUs",
                type: "item",
                url: "/skus",
            },
            {
                id: "mapping",
                title: "Mapping",
                type: "item",
                url: "/mapping",
            },
        ],
    },
    {
        id: "reports",
        title: "Reports",
        type: "collapse",
        icon: <AssessmentOutlinedIcon/>,
        permissions: [
            permissions.ViewProductQuantityReport,
            permissions.ViewProductBySupplierReport,
            permissions.ViewProductAvailabilityReport,
        ],
        children: [
            {
                id: "history-report",
                title: "History",
                type: "collapse",
                icon: <HistoryIcon/>,

                permissions: [permissions.ViewProductAvailabilityReport],
                children: [
                    {
                        id: "auditTrail-report",
                        title: "Audit Trail",
                        type: "item",
                        url: "/auditTrail-report",
                        childLvlTwo: true,
                        permissions: [permissions.ViewProductAvailabilityReport],
                    },
                ],
            },
            {
                id: "operation-report",
                title: "Operations",
                type: "collapse",
                icon: <PsychologyOutlinedIcon/>,

                permissions: [permissions.ViewProductAvailabilityReport],
                children: [
                    {
                        id: "availability-report",
                        title: "Item Availability",
                        type: "item",
                        url: "/availability-report",
                        childLvlTwo: true,
                        // icon: <FindInPageOutlinedIcon />,
                        permissions: [permissions.ViewProductAvailabilityReport],
                    },
                    {
                        id: "supplier-report",
                        title: "Search By Supplier",
                        type: "item",
                        url: "/supplier-report",
                        childLvlTwo: true,
                        // icon: <SearchOutlinedIcon />,
                        permissions: [permissions.ViewProductBySupplierReport],
                    },
                    {
                        id: "quantity-report",
                        title: "Inventory",
                        type: "item",
                        url: "/quantity-report",
                        childLvlTwo: true,
                        // icon: <HomeWorkOutlinedIcon />,
                        permissions: [permissions.ViewProductQuantityReport],
                    },
                    // {
                    //   id: "batch-availability",
                    //   title: "Batch Availability",
                    //   type: "item",
                    //   url: "/batch-availability",
                    //   // icon: <HomeWorkOutlinedIcon />,
                    //   //TODO
                    //   permissions: [permissions.ViewProductQuantityReport],
                    // },
                    {
                        id: "Non-serialized",
                        title: "Non-Serialized",
                        type: "item",
                        url: "/non-serialized",
                        childLvlTwo: true,
                        // icon: <HomeWorkOutlinedIcon />,
                        //TODO
                        permissions: [permissions.ViewProductQuantityReport],
                    },
                    {
                        id: "decanting-report",
                        title: "Decanting Report",
                        type: "item",
                        url: "/decanting-report",
                        childLvlTwo: true,
                        // icon: <HomeWorkOutlinedIcon />,
                        //TODO
                        permissions: [permissions.ViewProductQuantityReport],
                    },
                ],
            },
        ],
    },
    {
        id: "administration",
        title: "Administration",
        type: "title",
        permissions: [
            permissions.PartnerView,
            permissions.ProductView,
            permissions.UserManagement,
        ],
        children: [
            {
                id: "permit-number",
                title: "Permit Number",
                type: "item",
                icon: process.env.PUBLIC_URL + "/assets/svgs/ticket.svg",
                url: "/permit-number",
                // permissions: [permissions.Machine_View],
            },
            {
                id: "admin-panel",
                title: "Admin Panel",
                type: "item",
                icon: process.env.PUBLIC_URL + "/assets/svgs/admin.svg",
                url: "/admin-panel",
                permissions: [permissions.PartnerView],
            },
            {
                id: "users",
                title: "Users",
                type: "item",
                url: "/users",
                icon: process.env.PUBLIC_URL + "/assets/svgs/users.svg",
                permissions: [permissions.UserManagement],
            },
            {
                id: "roles",
                title: "Roles",
                type: "item",
                url: "/roles",
                icon: <ManageAccountsOutlinedIcon/>,
                permissions: [permissions.UserManagement],
            },
        ],
    },
];
