// Background colors
const whiteBackgroundColor = "#FFF";
const whiteColor = "#FFF";
const blackColor = "#000";
const overlayBlackColor = "rgba(0,0,0,0.5)";

// Text color
const greyColor = "#bbb";
const lightGreyColor = "#e0e0e0";
const lighterGreyColor = "#f2f2f2";
const overlayGrayColor = "rgba(242,242,242,0.5)";
const babyBlueColor = "#f3f6f9";

// Action colors
const redColor = "#c30000cc";

const yellowColor = "#e1b412";
const lemonGreenColor = "#7AC16C"; // #247106
const lighterLemonGreenColor = "rgba(122, 193, 108, 0.05)";

// Branding Colors
const brandingGreen = "#4b883f";
const brandingRed = "#801333";
const brandingLightRed = "#ead3c1";
const brandingBrown = "#574143";

const ThemeColors = {
  redColor,
  greyColor,
  whiteColor,
  blackColor,
  yellowColor,
  overlayGrayColor,
  overlayBlackColor,
  lemonGreenColor,
  lightGreyColor,
  lighterGreyColor,
  lighterLemonGreenColor,
  brandingRed,
  brandingLightRed,
  brandingGreen,
  brandingBrown,
  babyBlueColor,
  whiteBackgroundColor,
};

export default ThemeColors;
