import AppBarUserIcon from "./AppBarUserIcon";
import MenuIcon from "@mui/icons-material/Menu";
import {IconButton, Toolbar} from "@mui/material";
import ThemeColors from "../../config/colors";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

const CustomAppBar = ({
                          open,
                          handleDrawerOpen,
                          drawerWidth
                      }) => {
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({theme, open}) => ({
        background: ThemeColors.babyBlueColor,
        // background: theme.palette.background.default,
        zIndex: theme.zIndex.drawer + 2,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        borderBottom: `1px solid ${ThemeColors.greyColor}`
    }));

    return (
        <AppBar
            position="fixed"
            open={open}
            elevation={0}
        >
            <Toolbar className="justify-content-between">
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={handleDrawerOpen}
                    size="large"
                    sx={{
                        ...(open && { visibility: 'hidden' }),
                    }}>
                    <MenuIcon sx={{color: ThemeColors.brandingGreen}}/>
                </IconButton>
                <AppBarUserIcon />
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;
