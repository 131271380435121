import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import CustomAppBar from "./CustomAppBar";
import CustomDrawer from "./CustomDrawer";

const MiniDrawer = ({history, drawerWidth}) => {
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <CssBaseline/>
            <CustomAppBar
                history={history}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                drawerWidth={drawerWidth}
            />
            <CustomDrawer
                history={history}
                open={open}
                handleDrawerClose={handleDrawerClose}
                drawerWidth={drawerWidth}
            />
        </>
    );
}

export default MiniDrawer