import * as React from "react";
import {Box, Typography} from "@mui/material";
import {COMPANY_NAME} from "../../config/constants";
import originLogo from "../../config/assets/origin-original-logo.png";

const CustomDrawerFooter = () => {
    return (
        <Box
            className="d-flex justify-content-center align-items-end p-2 h-100"
            dir="ltr"
        >
            <Typography variant="body2" className="px-2">
                Powered by
            </Typography>

            <Box
                component='img'
                alt={COMPANY_NAME}
                sx={{
                    width: {xs: '25%'},
                }}
                src={originLogo}
            />
            <Typography variant="subtitle1" className="px-2">
                {process.env.REACT_APP_VERSION}
            </Typography>
        </Box>
    );
};

export default CustomDrawerFooter;
